import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactForm from "../components/contact-form"
import ContactJSON from "../content/contact.json"
import { FontAwesomeIcon  } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faEnvelope, faPhone  } from '@fortawesome/free-solid-svg-icons'
import "animate.css/animate.min.css";
import { Link } from 'gatsby'

export default function contact() {
    return (
        <Layout>
            <Seo title="Contact Us" />
            <img className="parallax" src="/building.jpg" alt="Parallax"/>
            <div className="banner-cover" />
            <div className="banner animate__animated fadeInDownSmall">
                <h1 className="display-3"><b>{ContactJSON.title}</b></h1>
            </div>
            <Container fluid className="bg-white" style={{padding: '4% 0 0', boxShadow: 'inset 0 5px 5px #bebebe'}}>
                <Container className="mt-0">
                    <Row className="justity-content-between">
                        <Col lg={6} className="d-flex flex-column mb-5">
                            <h5>{ContactJSON.title}</h5>
                            <p>{ContactJSON.description}</p>
                            <div className="d-flex flex-column mt-5">
                                <h6>{ContactJSON.office}</h6>
                                <span className="mb-3">
                                    <FontAwesomeIcon className="mr-3" style={{color: "#242943"}} icon={faMapMarkerAlt} />
                                    <a target="_blank" rel="noreferrer" href="https://goo.gl/maps/woHAnZN8uAVA4aE68" style={{color: "#242943"}}>{ContactJSON.address}</a>
                                </span>
                                <span className="mb-3">
                                    <FontAwesomeIcon className="mr-3" style={{color: "#242943"}} icon={faEnvelope} />
                                    <span style={{color: "#242943"}}>{ContactJSON.email}</span>
                                </span>
                                <span className="mb-3">
                                    <FontAwesomeIcon className="mr-3" style={{color: "#242943"}} icon={faPhone} />
                                    <a href={`tel:`+ContactJSON.phone} style={{color: "#242943"}}>{ContactJSON.phone}</a>
                                </span>
                            </div>
                            <div className="d-flex flex-column my-5">
                                <h6>{ContactJSON.eastOffice}</h6>
                                <span className="mb-3">
                                    <FontAwesomeIcon className="mr-3" style={{color: "#242943"}} icon={faMapMarkerAlt} />
                                    <a target="_blank" rel="noreferrer" href="https://goo.gl/maps/woHAnZN8uAVA4aE68" style={{color: "#242943"}}>{ContactJSON.eastAddress}</a>
                                </span>
                            </div>
                            <h5>Join Us</h5>
                            <p>If you are interested in joining our team, check our <Link to="/careers" style={{color: "#27476E"}}>careers portal</Link> to view open jobs and apply. We look forward to your application!</p>

                        </Col>
                        <Col lg={6} className="d-flex flex-column mb-4">
                            <ContactForm />
                        </Col>
                    </Row>
                </Container>
                <Row className="mx-0">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d19252.232037119433!2d-117.1185522623063!3d32.77759588945454!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x70ef3727c978d6e1!2sTrabus%20Technologies!5e0!3m2!1sen!2sus!4v1625000112658!5m2!1sen!2sus"
                        height={450}
                        style={{border: 0, width: "100%"}}
                        title="Trabus Technologies Google Map"
                        loading="lazy">
                    </iframe>
                </Row>
            </Container>
        </Layout>
    )
}
